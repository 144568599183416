<template>
  <div class="form-box">
    <div class="form">
      <van-form validate-first class="form" @submit="submit">
        <!-- 街道 -->
        <van-field
          readonly
          clickable
          is-link
          label="街道"
          required
          :value="form.streetName"
          placeholder="请选择街道"
          @click="showStreetPicker = true"
          :rules="[{ required: true }]"
        >
          <span slot="label" class="field-label">街道</span>
        </van-field>
        <van-popup v-model="showStreetPicker" round position="bottom">
          <van-picker
            show-toolbar
            value-key="deptName"
            :columns="streetList"
            @cancel="showStreetPicker = false"
            @confirm="onConfirmStreet"
          />
        </van-popup>
        <!-- 社区 -->
        <van-field
          readonly
          clickable
          is-link
          required
          label="社区"
          :value="form.communityName"
          placeholder="请选择社区"
          @click="showPicker1 = true"
          :rules="[{ required: true }]"
        >
          <span slot="label" class="field-label">社区</span>
        </van-field>
        <van-popup v-model="showPicker1" round position="bottom">
          <van-picker
            show-toolbar
            value-key="deptName"
            :columns="shequList"
            @cancel="showPicker1 = false"
            @confirm="onConfirm1"
          />
        </van-popup>
        <!-- 姓名 -->
        <van-field
          v-model="form.realName"
          label="姓名"
          required
          :rules="[{ required: true }]"
          placeholder="请输入用户名"
        />
        <!-- 性别 -->
        <van-field
          v-model="form.sex"
          label="性别"
          is-link
          required
          readonly
          @click="showSex = true"
          :rules="[{ required: true }]"
          placeholder="请选择性别"
        />
        <van-popup v-model="showSex" round position="bottom">
          <van-picker
            show-toolbar
            value-key="dictValue"
            :columns="sexList"
            @cancel="showSex = false"
            @confirm="setSex"
          />
        </van-popup>
        <!-- 民族 -->
        <van-field
          v-model="form.nation"
          label="民族"
          is-link
          required
          readonly
          :rules="[{ required: true }]"
          @click="showNation = true"
          placeholder="请输入民族"
        />
        <van-popup v-model="showNation" round position="bottom">
          <van-picker
            show-toolbar
            value-key="dictValue"
            :columns="nationList"
            @cancel="showNation = false"
            @confirm="setNation"
          />
        </van-popup>
        <!-- 身份证号码 -->
        <van-field
          v-model="form.cardId"
          label="身份证号码"
          required
          :rules="[{ required: true }]"
          placeholder="请输入身份证号码"
        />
        <!-- 户籍地址 -->
        <van-field
          v-model="form.familyAddress"
          label="户籍地址"
          required
          :rules="[{ required: true }]"
          placeholder="请输入户籍地址"
        />
        <!-- 户籍详细地址 -->
        <van-field
          v-model="form.familyDetailedAddress"
          label="户籍详细地址"
          required
          :rules="[{ required: true }]"
          placeholder="请输入户籍详细地址"
        />
        <!-- 居住地址 -->
        <van-field
          v-model="form.dwellAddress"
          label="居住地址"
          required
          :rules="[{ required: true }]"
          placeholder="请输入居住地址"
        />
        <!-- 居住详细地址 -->
        <van-field
          v-model="form.dwellDetailedAddress"
          label="居住详细地址"
          required
          :rules="[{ required: true }]"
          placeholder="请输入居住详细地址"
        />
        <!-- 联系方式 -->
        <van-field
          v-model="form.phone"
          label="联系方式"
          required
          :rules="[{ required: true }]"
          placeholder="请输入联系方式"
        />
        <!-- 提交 -->
        <van-button
          block
          class="submit-btn"
          native-type="submit"
          type="info"
          :loading="subLoading"
        >
          提交
        </van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import { levelList } from '@/api/registerApi'

import { getNationList, getSexList, save } from '@/api/registrationApi'
export default {
  name: 'RegistrationVoters',
  data() {
    return {
      visitor: {
        faceUrl: '',
        houseId: '',
      },
      form: {
        streetId: '',
        streetName: '',
        communityId: '',
        communityName: '',
        realName: '',
        sex: '',
        nation: '',
        cardId: '',
        familyAddress: '',
        familyDetailedAddress: '',
        dwellAddress: '',
        dwellDetailedAddress: '',
        phone: '',
        checkRes: 0,
        openId: JSON.parse(localStorage.getItem('yt-openId')).content,
      },
      showStreetPicker: false,
      showPicker1: false,
      showPicker2: false,
      subLoading: false,
      showNation: false, // 民族弹窗
      showSex: false, // 性别弹窗显示
      streetList: [], // 街道列表
      shequList: [], // 社区列表
      nationList: [], // 民族列表
      sexList: [], // 性别下拉
    }
  },
  created() {
    this.getLevelList()
    this.getNation()
    this.getSex()
    this.form.tenantId = this.$store.getters.tenantId
  },
  methods: {
    // 选择性别
    setSex(val) {
      // this.form.sex = val.id;
      this.form.sex = val.dictValue
      this.showSex = false
    },
    // 获取民族
    async getNation() {
      const res = await getNationList()

      if (res.code == 200) {
        this.nationList = res.data
      }
    },
    // 获取性别
    async getSex() {
      const res = await getSexList()

      if (res.code == 200) {
        this.sexList = res.data
      }
    },
    // 设置民族
    setNation(val) {
      this.showNation = false
      this.form.nation = val.dictValue
    },
    // 提交表单回调
    async submit() {
      const { form } = this
      const res = await save(form)
      if (res.code == 200) {
        // this.form = {
        //   streetId: '',
        //   streetName: '',
        //   communityId: '',
        //   communityName: '',
        //   realName: '',
        //   sex: '',
        //   nation: '',
        //   cardId: '',
        //   familyAddress: '',
        //   familyDetailedAddress: '',
        //   dwellAddress: '',
        //   dwellDetailedAddress: '',
        //   phone: '',
        //   checkRes: 0,
        //   openId: JSON.parse(localStorage.getItem('yt-openId')).content,
        // }
        this.$toast({
          type: 'success',
          message: '登记成功',
          duration: 1500,
          onClose: () => {
            // this.$router.replace('/registrationList')
            // this.$router.go(-1)
            window.location.href = '/registrationList'
          },
        })
      } else {
        this.$toast({
          type: 'error',
          message: res.msg,
          duration: 1500,
        })
      }
    },
    getLevelList(parentId) {
      levelList({
        parentId: parentId,
        tenantId: parentId ? null : this.$store.getters.tenantId,
      }).then(res => {
        if (res.code === 200) {
          if (parentId) {
            this.shequList = res.data
          } else {
            this.streetList = res.data
          }
        }
      })
    },
    onConfirmStreet(val) {
      // this.visitor.communityId = val.id
      // this.visitor.communityName = val.deptName
      // this.visitor.tenantId = val.tenantId
      this.form.streetId = val.id
      this.form.streetName = val.deptName
      // this.form.tenantId = val.tenantId;
      this.getLevelList(val.id)
      this.showStreetPicker = false
    },
    onConfirm1(val) {
      // this.visitor.communityId = val.id
      // this.visitor.shequName = val.deptName
      this.form.communityId = val.id
      this.form.communityName = val.deptName
      this.showPicker1 = false
    },
  },
}
</script>

<style lang="scss" scoped>
// .wrap {
//   .form {
//     .show-record {
//       font-size: 16px;
//       color: #ccc;
//       text-align: center;
//       margin-bottom: 30px;
//     }
//   }
// }
</style>
